import { Profile } from "./profile";

export interface Request {
    id: number;
    title: string;
    date: Date;
    assetCode: string;
    description: string;
    isCancelled: boolean;
    isSystem: boolean;
    serviceDetail: string;
    hostUsername: string;
    hostDisplayname: string;
    appointmentDate: Date | null;
    statusId: number;
    categoryId: number;
    branchId: number;
    status: string;
    categoryName: string;
    branchName: string;
    quality: number;
    quick: number;
    serviceMind: number;
    attendees: Profile[];
    photos: Photo[];
}

export interface Filter {
    branches: FilterData[];
    status: FilterData[];
    category: FilterData[]
}

interface FilterData {
    id: number;
    name: string;
}

export interface ratting {
    type?: string;
    score?: number;
}

// export interface Status {
//     id: number;
//     name: string;
// }

// export interface Category {
//     id: number;
//     name: string;
// }

export interface Photo {
    id: string;
    url: string;
    isUser: boolean;
}

export class Request implements Request {

    constructor(init?: RequestFormValues) {
        // object is init,whatever we pass into the init object
        Object.assign(this, init);
    }
}

export class RequestFormValues {
    id?: number = undefined;
    date: Date = new Date();
    title: string = '';
    assetCode: string = '';
    description: string = '';
    isSystem: boolean = false;
    serviceDetail: string = '';
    branchId: number = 1;
    categoryId: number = 1;
    statusId: number = 1;
    categoryName: string = '';
    appointmentDate: Date | null = null;
    photos: Photo[] = []
   
    // load value from server
    constructor(request?: RequestFormValues) {
        if (request) {
            this.id = request.id;
            this.date = request.date;
            this.title = request.title;
            this.assetCode = request.assetCode;
            this.description = request.description;
            this.statusId = request.statusId;
            this.isSystem = request.isSystem;
            this.serviceDetail = request.serviceDetail;
            this.branchId = request.branchId;
            this.categoryId = request.categoryId;
            this.categoryName = request.categoryName;
            this.appointmentDate = request.appointmentDate;
            this.photos = request.photos;
        }
    }
}
