import React from "react";
import { Table } from "semantic-ui-react";
import { SumRequest } from "../../../app/models/report";

type Props = {
    data: SumRequest[];
}

export class RequestReportDetails extends React.PureComponent<Props> {
    render(): React.ReactNode {
        return (
            <>
                <Table striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ประเภทงาน</Table.HeaderCell>
                            <Table.HeaderCell>Hardware</Table.HeaderCell>
                            <Table.HeaderCell>Software</Table.HeaderCell>
                            <Table.HeaderCell>ทั้งหมด</Table.HeaderCell>
                            <Table.HeaderCell>ระหว่างซ่อม</Table.HeaderCell>
                            <Table.HeaderCell>เสร็จสิ้น</Table.HeaderCell>
                            <Table.HeaderCell>ความรวดเร็ว</Table.HeaderCell>
                            <Table.HeaderCell>คุณภาพ</Table.HeaderCell>
                            <Table.HeaderCell>ความใส่ใจ</Table.HeaderCell>
                            <Table.HeaderCell>คะแนนเฉลี่ย</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.data.map((item) =>
                        (<Table.Row key={item.type}>
                            <Table.Cell>{item.type}</Table.Cell>
                            <Table.Cell textAlign="center">{item.numHardware}</Table.Cell>
                            <Table.Cell textAlign="center">{item.numSoftware}</Table.Cell>
                            <Table.Cell textAlign="center">{item.totalJob}</Table.Cell>
                            <Table.Cell textAlign="center">{item.wipJob}</Table.Cell>
                            <Table.Cell textAlign="center">{item.finishJob}</Table.Cell>
                            <Table.Cell textAlign="center">{item.quickRatting.toFixed(2)}</Table.Cell>
                            <Table.Cell textAlign="center">{item.qualityRatting.toFixed(2)}</Table.Cell>
                            <Table.Cell textAlign="center">{item.serviceMindRatting.toFixed(2)}</Table.Cell>
                            <Table.Cell textAlign="center">{item.avgRatting.toFixed(2)}</Table.Cell>
                        </Table.Row>)
                        )}
                    </Table.Body>
                </Table>
            </>
        )
    }
}

