import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Grid, Pagination } from "semantic-ui-react";
import { PagingParams } from "../../../app/models/pagination";
import { useStore } from "../../../app/stores/store";
import RequestFilters from "./RequestFilters";
import RequestList from "./RequestList";
import RequestListItemPlaceholder from "./RequestListItemPlaceholder";

export default observer(function RequestDashboard() {
  const { requestStore } = useStore();
  const { loadRequests, requestRegistry, setPagingParams, pagination } = requestStore;
  const [loadingNext, setLoadingNext] = useState(false);

  function handlePageChange(activePage: number) {
    setLoadingNext(true);
    setPagingParams(new PagingParams(activePage));
    loadRequests().then(() => setLoadingNext(false));
  }

  useEffect(() => {
    if (requestRegistry.size === 0) loadRequests();
  }, [requestRegistry.size, loadRequests])

  return (
    <Grid >
      <Grid.Column width={6}>
        <RequestFilters />

      </Grid.Column>
      <Grid.Column width={10}>
        {requestStore.loadingInitial || loadingNext ? (
          <>
            <RequestListItemPlaceholder />
            <RequestListItemPlaceholder />
            <RequestListItemPlaceholder />
            <RequestListItemPlaceholder />
          </>
        ) : (
          <RequestList />
        )}

      </Grid.Column>
      <Grid.Column width={6} />
      <Grid.Column width={8} style={{ marginTop: -10 }}>
      {requestRegistry.size !== 0 &&
        <Pagination
          totalPages={pagination?.totalPages === undefined ? 1 : pagination.totalPages}
          activePage={pagination?.currentPage}
          onPageChange={(e, data) => handlePageChange(data.activePage as number)}
        />}
      </Grid.Column>
    </Grid>
  )
})