import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Header, Image, Item, Label, Segment } from "semantic-ui-react";
import { Request } from "../../../app/models/request";
import { useStore } from "../../../app/stores/store";

const requestImageStyle = {
    filter: 'brightness(30%)',
};

const requestImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};

interface Props {
    request: Request;
}

export default observer(function RequestDetailHeader({ request }: Props) {
    const { requestStore: { cancelRequestToggle, loading, updateRequestStatus }, userStore: { user } } = useStore();
    const [target, setTarget] = useState(0);

    function handleSetStatus(statusId: number) {
        updateRequestStatus(statusId);
        setTarget(statusId);
    }

    function handleCancelRequest() {
        cancelRequestToggle();
        setTarget(0);
    }

    return (
        <Segment.Group>
            <Segment basic attached='top' style={{ padding: '0' }}>
                {request.isCancelled &&
                    <Label
                        style={{ position: 'absolute', zIndex: 1000, left: -14, top: 20 }}
                        ribbon color="red" content='Cancelled'
                    />
                }
                <Image src={`/assets/it.jpg`} fluid style={requestImageStyle} />
                <Segment style={requestImageTextStyle} basic>
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                <Header
                                    size="huge"
                                    content={request.title}
                                    style={{ color: 'white' }}
                                />
                                <p>{format(request.date!, 'dd MMM yyyy')}</p>
                                <p>
                                    แจ้งโดย <strong><Link to={`/profiles/${request.hostUsername}`}>
                                        {request.hostDisplayname}</Link></strong>
                                </p>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Segment>
            </Segment>

            <Segment clearing attached='bottom'>
                <>
                    {!user?.roles?.includes('Admin') &&
                        <Button
                            key='cancel'
                            color={request.isCancelled ? 'green' : 'red'}
                            floated='left'
                            basic
                            content={request.isCancelled ? 'ไม่ยกเลิก' : 'ยกเลิก'}
                            disabled={request.status === 'ปิดงาน'}
                            onClick={handleCancelRequest}
                            loading={loading && target === 0}
                        />
                    }
                    <Button
                        color='orange'
                        content='แก้ไข'
                        style={{ marginLeft: '100' }}
                        as={Link}
                        disabled={request.isCancelled || request.status === 'ปิดงาน'}
                        to={`/manage/${request.id}`}
                    />
                    {!user?.roles?.includes('Admin') &&
                        <Button
                            color={request.status !== 'ปิดงาน' ? 'blue' : 'green'}
                            content='ปิดงาน'
                            style={{ marginLeft: '100' }}
                            disabled={request.isCancelled || request.status === 'ปิดงาน'}
                            onClick={() => handleSetStatus(6)}
                            loading={loading && target === 5}
                        />
                    }
                    {user?.roles?.includes('Admin') &&
                        <Button.Group
                            floated="right"
                        >
                            <Button
                                color={request.status !== 'ดำเนินการซ่อม' ? 'blue' : 'green'}
                                disabled={request.isCancelled || request.status === 'ปิดงาน'}
                                content='ดำเนินการซ่อม'
                                onClick={() => handleSetStatus(2)}
                                loading={loading && target === 2}
                            />
                            <Button
                                color={request.status !== 'ซ่อมเสร็จ' ? 'blue' : 'green'}
                                disabled={request.isCancelled || request.status === 'ปิดงาน'}
                                content='ซ่อมเสร็จ'
                                onClick={() => handleSetStatus(3)}
                                loading={loading && target === 3}
                            />
                            <Button
                                color={request.status !== 'ซ่อมไม่ได้' ? 'blue' : 'green'}
                                disabled={request.isCancelled || request.status === 'ปิดงาน'}
                                content='ซ่อมไม่ได้'
                                onClick={() => handleSetStatus(4)}
                                loading={loading && target === 4}
                            />
                        </Button.Group>
                    }
                </>
            </Segment>

        </Segment.Group>
    )
})