import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { makeAutoObservable, runInAction } from "mobx";
import { ChatComment } from "../models/comment";
import { store } from "./store";

export default class CommentStore {
    comments: ChatComment[] = [];
    hubConnection: HubConnection | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    createHubConnection = (requestId: string) => {
        if (store.requestStore.selectedRequest) {
            this.hubConnection = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_CHAT_URL + '?requestId=' + requestId, {
                    accessTokenFactory: () => store.userStore.user?.token!
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build();

            this.hubConnection.start().catch(error => console.log('Error establishing the connection: ', error));

            this.hubConnection.on('LoadComments', (comments: ChatComment[]) => {
                runInAction(() => {

                    comments.forEach(comment => {
                        comment.createdAt = new Date(comment.createdAt);
                    })
                    this.comments = comments

                });
            })

            this.hubConnection.on('ReceiveComment', (comment: ChatComment) => {
                runInAction(() => {
                    let createAt = comment.createdAt.toString();
                    comment.createdAt = new Date(createAt.substring(0, createAt.length - 1));
                    //this.comments.push(comment);
                    this.comments.unshift(comment);
                })
            })

        }
    }

    stopHubConnection = () => {
        this.hubConnection?.stop().catch(error => console.log(`Error stopping connection: ${error}`));
    }

    clearComments = () => {
        this.comments = [];
        this.stopHubConnection();
    }

    addComment = async (values: any) => {
        values.requestId = store.requestStore.selectedRequest?.id;
        try {
            await this.hubConnection?.invoke('SendComment', values);
        } catch (error) {
            console.log(error);
        }
    }

} 