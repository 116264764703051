import { ErrorMessage, Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { Button, Header } from 'semantic-ui-react'
import MyTextInput from '../../app/common/form/MyTextInput'
import { useStore } from '../../app/stores/store'
import * as Yup from 'yup';
import _ from 'lodash';
import ValidationErrors from '../errors/ValidationErrors'
import MySelectInput from '../../app/common/form/MySelectInput'
import useRequest from '../../app/common/hook/useRequest'
import LoadingComponent from '../../app/layout/LoadingComponent'

export default observer(function RegisterForm() {
    const { userStore } = useStore();
    const { filter } = useRequest();

    const categoryOptions = _.map(filter?.branches, (item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
    }))
    function selectedChangeHandle() {
    }
    if (categoryOptions.length === 0) return <LoadingComponent content='Loading Data...' inverted={false} />

    return (
        <Formik
            initialValues={{ displayName: '', username: '', email: '', password: '', branchId: 1, error: null }}
            onSubmit={(values, { setErrors }) =>
                userStore.register(values).catch(error =>
                    setErrors({ error }))}
            validationSchema={Yup.object({
                displayName: Yup.string().required(),
                username: Yup.string().required(),
                email: Yup.string().required().email(),
                password: Yup.string().required(),
            })}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off' >
                    <Header as='h2' content='ลงทะเบียนผู้ใช้งาน' color='teal' textAlign='center' />
                    <MyTextInput name='displayName' placeholder='ชื่อที่ใช้แสดง' />
                    <MyTextInput name='username' placeholder='ชื่อผู้ใช้' />
                    <MyTextInput name='email' placeholder='อีเมล' />
                    <MyTextInput name='password' placeholder='รหัสผ่าน' type='Password' />
                    <MySelectInput
                        placeholder="สาขา"
                        name='branchId'
                        options={categoryOptions}
                        selectedChangeHandle={selectedChangeHandle}
                    />
                    <ErrorMessage
                        name='error' render={() =>
                            <ValidationErrors errors={errors.error} />}
                    />
                    <Button
                        disabled={!isValid || !dirty || isSubmitting}
                        loading={isSubmitting} positive content='Register' type='submit' fluid />
                </Form>
            )}

        </Formik>
    )
})
