import { useField } from "formik";
import { Dropdown, Form, Label } from "semantic-ui-react";

interface Props {
    placeholder: string;
    name: string;
    options: any;
    label?: string;
    disabled?: boolean;
    selectedChangeHandle: (value: any) => void;
}

// !! = match object to boolean
export default function MySelectInput(props: Props) {
    const [field, meta, helpers] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <label>{props.label}</label>
            <Dropdown
                fluid
                selection
                options={props.options}
                value={props.options[field.value - 1].value || null}
                onChange={(e, d) => {
                    helpers.setValue(d.value);
                    props.selectedChangeHandle(d.value);
                }}
                onBlur={() => helpers.setTouched(true)}
                placeholder={props.placeholder}
                disabled={props.disabled}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}