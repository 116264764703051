import { observer } from "mobx-react-lite";
import { Container, Header, Segment } from 'semantic-ui-react';
import { useStore } from "../../app/stores/store";

export default observer(function ServerError() {
    const { commonStore } = useStore();
    return (
        <>
            <Container fluid>
                <Header as='h1'>Server Error</Header>
                <Header sub as='h5' color='red'>{commonStore.error?.message}</Header>
                {commonStore.error?.details && 
                    <Segment>
                        <Header as='h4' color='teal'>Stack trace</Header>
                        <code style={{marginTop: '10px'}}>{ commonStore.error?.details}</code>
                </Segment>
                }
            </Container>
        </>
    )
})