import { observer } from "mobx-react-lite";
import { SyntheticEvent, useState } from "react";
import { Button, Card, Grid, Header, Image, Segment } from "semantic-ui-react";
import { Photo, Request } from "../../../app/models/request";
import { useStore } from "../../../app/stores/store";

interface Props {
  request: Request;
  isUser: boolean;
}

export default observer(function RequestDetailPhoto({ request,isUser }: Props) {
  const { requestStore: { loading }, modalStore } = useStore();
  const [target, setTarget] = useState('');
  function handleShowPhoto(photo: Photo, e: SyntheticEvent<HTMLButtonElement>) {
    setTarget(e.currentTarget.name);
    modalStore.openModal(<Image src={photo.url} />,'small')
  }
  return (
    <Segment>
      <Grid>
        <Grid.Column width={16}>
          <Header floated='left' icon='image' content='รูปภาพ' color="teal"/>
        </Grid.Column>
        <Grid.Column width={16}>
          <Card.Group itemsPerRow={5}>
            {request.photos.map(photo => (
              photo.isUser === isUser &&
              <Card key={photo.id}>
                <Image src={photo.url} />
                <Button
                      color='green'
                      content='แสดง'
                      name={'show' + photo.id}
                      loading={target === 'show' + photo.id && loading}
                      onClick={e => handleShowPhoto(photo, e)}
                    />
              </Card>
            ))}
          </Card.Group>
        </Grid.Column>
      </Grid>
    </Segment>
  )
})