import { Button, Grid, Header, Segment } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { RequestReportDetails } from "./RequestReportDetails";
import agent from "../../../app/api/agent";
import { SumRequestValue } from "../../../app/models/report";


export default function RequestReportHeader() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [sumRequest, setSumRequest] = useState<SumRequestValue[]>([]);
    const pageStyle = `{ size: A4 landscape }`;
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        pageStyle: pageStyle,
        content: () => componentRef.current,
    });

    async function getData() {
        setLoading(true);
        try {
            setSumRequest([]);
            const result = await agent.Reports.sumReport(startDate.toISOString(), endDate.toISOString());
            result.forEach(sumRequest => {
                setSumRequest((prev) => [...prev, sumRequest]);
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <Segment>
            <Header
                size="huge"
                textAlign='center'
                content="รายงานสรุปการทำงาน"
                style={{ color: 'black' }}
            />
            <Grid columns='equal' style={{ marginTop: 30, marginBottom: 20 }}>
                <Grid.Column width={1} />
                <Grid.Column width={1} verticalAlign="middle" style={{ marginRight: -25 }}>
                    <span>วันที่</span>
                </Grid.Column>
                <Grid.Column width={3} verticalAlign="middle">
                    <DatePicker dateFormat="dd/MM/yyyy" selected={startDate} onChange={(date) => setStartDate(date!)} />
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle" style={{ marginRight: -30, marginLeft: -10 }}>
                    <span>ถึง</span>
                </Grid.Column>
                <Grid.Column width={3} verticalAlign="middle">
                    <DatePicker dateFormat="dd/MM/yyyy" selected={endDate} onChange={(date) => setEndDate(date!)} />
                </Grid.Column>
                <Grid.Column >
                    <Button primary loading={loading} content="แสดงข้อมูล" size="mini" onClick={getData} />
                    <Button color="green" content="พิมพ์รายงาน" size="mini" onClick={handlePrint} />
                </Grid.Column>
            </Grid>
            <RequestReportDetails ref={componentRef} data={sumRequest} />
        </Segment>
    )
}