import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { Calendar } from "react-calendar";
import { Checkbox, Grid, Header, Input, Label, Menu, Segment } from "semantic-ui-react";
import useRequest from "../../../app/common/hook/useRequest";
import { useStore } from "../../../app/stores/store";
import debounce from "lodash/debounce";

export default observer(function RequestFilters() {
    const { requestStore: { predicate, setPredicate, searchTerm, branchFilter, pagination }, userStore: { user } } = useStore();
    const [search, setSearch] = useState(searchTerm);
    const [checkedItems, setCheckedItems] = useState(branchFilter);
    const { filter } = useRequest();

    const handleFilterStatus = (name: string) => {
        setPredicate('status', name);
    }

    const searchQuery = (query: string) => {
        // Call API with query parameter here
        setPredicate('searchTerm', query);

    };

    const debounceSearch = debounce((query) => searchQuery(query), 500);

    const handleSearch = useCallback(
        (event: string) => {
            debounceSearch(event) // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    function handleFilterBranch(value: string) {
        const currentIndex = checkedItems.findIndex(item => item === value)
        let newChecked: string[] = [];
        if (currentIndex === - 1) {
            newChecked = [...checkedItems, value];
        } else {
            newChecked = checkedItems.filter(item => item !== value);
        }
        setCheckedItems(newChecked);
        setPredicate('branch', newChecked);
    }

    return (
        <>
            <Segment style={{ width: '87%', marginTop: 27 }}>
                <Input
                    fluid
                    icon='search'
                    placeholder='ค้นหา...'
                    value={search}
                    onChange={(e, event) => {
                        setSearch(event.value);
                        handleSearch(event.value)
                    }}
                />
            </Segment>
            <Menu vertical size="large" style={{ width: '87%', marginTop: 20 }}>
                <Header icon='filter' attached color="teal" content='Filters' />
                {filter?.status.map((filterStatus) => (
                    <Menu.Item
                        key={filterStatus.id}
                        active={predicate.get('status') === filterStatus.name}
                        onClick={() => handleFilterStatus(filterStatus.name)}
                    >
                        <Label
                            circular color='teal'
                            content={
                                filterStatus.id === 1 ? pagination?.numOpenJob :
                                    filterStatus.id === 2 ? pagination?.numWipJob :
                                        filterStatus.id === 3 ? pagination?.numFinishJob :
                                            filterStatus.id === 4 ? pagination?.numNotRepairJob :
                                                filterStatus.id === 5 ? pagination?.numCancelJob :
                                                    filterStatus.id === 6 ? pagination?.numCloseJob : 0
                            }
                        />
                        {filterStatus.name}
                    </Menu.Item>
                ))}
            </Menu>
            <Header style={{ width: '87%', marginTop: 20 }} />
            <Calendar
                locale={"en-EN"}
                onChange={(date: any) => {
                    setPredicate('startDate', date as Date)
                    //console.log(date)
                }}
                value={predicate.get('startDate' || new Date())}
            />
            {user?.roles?.includes('Admin') &&
                <Segment style={{ width: '87%', marginTop: 20, overflow: 'auto', maxHeight: '31vh' }}>
                    {filter?.branches.map((branch) => (
                        <Grid.Column key={branch.id} style={{ padding: 4, marginTop: 2, marginBottom: 2 }}>
                            <Checkbox
                                key={branch.id}
                                label={branch.name}
                                name={branch.name}
                                checked={checkedItems.indexOf(branch.name) !== -1}
                                onClick={() => handleFilterBranch(branch.name)}
                            />
                        </Grid.Column>
                    ))}
                </Segment>
            }
        </>
    )
})