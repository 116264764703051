import { useEffect } from "react";
import { useStore } from "../../stores/store"

export default function useRequest() {
    const { requestStore: { loadFilters, filter, loadingFilter } } = useStore();

    useEffect(() => {
        if (!loadingFilter) loadFilters();
    }, [loadingFilter, loadFilters])
    return {
        filter
    }
}