import { Button, Header, Segment } from "semantic-ui-react";
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yap from 'yup';
import { useEffect, useState } from "react";
import { RequestFormValues } from "../../../app/models/request";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MyDateInput from "../../../app/common/form/MyDateInput";
import { Link, useHistory, useParams } from "react-router-dom";
import RequestPhotoForm from "./RequestPhotoForm";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import MySelectInput from "../../../app/common/form/MySelectInput";
import useRequest from "../../../app/common/hook/useRequest";
import _ from 'lodash';
import MyCheckBox from "../../../app/common/form/MyCheckBox";

export default observer(function RequestForm() {
    const history = useHistory();
    const { requestStore, userStore } = useStore();
    const { createRequest, updateRequest, loadRequest, loadingInitial, selectedRequest, clearSelectedRequest } = requestStore;
    const { filter } = useRequest();
    const { id } = useParams<{ id: string }>();
    const [request, setRequest] = useState<RequestFormValues>(new RequestFormValues());
    const [checked, setChecked] = useState(request.isSystem);
    const categoryOptions = _.map(filter?.category, (item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
    }))
    const validationSchema = Yap.object({
        title: Yap.string().required('กรุณาป้อน เรื่อง'),
        assetCode: Yap.string().required('กรุณาป้อน รหัสทรัพย์สิน'),
        description: Yap.string().required('กรุณาป้อน รายละเอียดของปัญหา'),
    })

    useEffect(() => {
        if (id) {
            loadRequest(parseInt(id)).then(request => {
                setRequest(new RequestFormValues(request))
                setChecked(request?.isSystem as boolean);
            });
        } else {
            clearSelectedRequest();
        }

    }, [id, loadRequest, clearSelectedRequest])

    function selectedChangeHandle(value: any) {
        request.categoryName = categoryOptions[value - 1].text;
    }

    async function handleFormSubmit(values: RequestFormValues,submitProps: FormikHelpers<RequestFormValues>) {
        values = {
            ...values,
            isSystem: checked
        };
        if (!!selectedRequest?.id) {
            values = {
                ...values,
                id: selectedRequest.id
            }
        }
        // console.log('submit: ',values);
        if (!values.id) {
            // createRequest(values).then(() => {
            //     submitProps.setSubmitting(false)
            //     submitProps.resetForm({values});
            // });
            // const result = await createRequest(values);
            await createRequest(values);
            submitProps.setSubmitting(false);
            submitProps.resetForm({values});
            
        } else {
            values = {
                ...values,
                appointmentDate: !!values.appointmentDate && values.appointmentDate!.getFullYear() > 2001 ? values.appointmentDate : null
            };
            updateRequest(values).then(() => history.push(`/requests/${values.id}`));
        }
    }

    const handleCheckChange = (value: boolean) => {
        setChecked(value);
    }

    if (loadingInitial) return <LoadingComponent content='Loading Data...' />
    // !!selectedRequest?.id ==> selectedRequest?.id !== undefined
    return (
        <>
            <Segment.Group>
                <Segment clearing attached='top' >
                    <Header content='ใบแจ้งซ่อม' color="teal" />
                    <Formik
                        validationSchema={validationSchema}
                        enableReinitialize
                        initialValues={request}
                        onSubmit={(values, submitProps) => handleFormSubmit(values, submitProps)}
                    >
                        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                            <Form className="ui form" onSubmit={handleSubmit} autoComplete='off'>
                                <MyTextInput
                                    placeholder="เรื่อง"
                                    name='title'
                                    disabled={userStore.user?.roles?.includes('Admin') || request.statusId === 6}
                                />
                                <MyDateInput
                                    placeholderText='Date'
                                    name='date'
                                    dateFormat='dd/MM/yyyy'
                                    disabled
                                />
                                <MyTextInput
                                    placeholder="ทะเบียนทรัพย์สิน"
                                    name='assetCode'
                                    disabled={userStore.user?.roles?.includes('Admin') || request.statusId === 6}
                                />
                                <MyCheckBox
                                    label="เกี่ยวกับระบบ"
                                    name='isSystem'
                                    disabled={userStore.user?.roles?.includes('Admin') || request.statusId === 6}
                                    checked={checked}
                                    checkedChange={handleCheckChange}
                                />
                                <MyTextArea
                                    placeholder='รายละเอียดของปัญหา'
                                    name='description'
                                    rows={3}
                                    disabled={userStore.user?.roles?.includes('Admin') || request.statusId === 6}
                                />
                                {!userStore.user?.roles?.includes('Admin') &&
                                    <>
                                        <Button
                                            disabled={isSubmitting || !dirty || !isValid}
                                            floated='right'
                                            loading={isSubmitting}
                                            positive type='submit'
                                            content='บันทึก'
                                        />
                                        <Button
                                            as={Link}
                                            to={!request.id ? '/dashboard' : `/requests/${request.id}`}
                                            floated='right'
                                            type='button'
                                            content='ยกเลิก'
                                        />
                                    </>
                                }
                            </Form>
                        )}
                    </Formik>
                </Segment>
                <Segment clearing attached='bottom' >
                    <RequestPhotoForm request={selectedRequest} roles='User' isUser={true} />
                </Segment>
            </Segment.Group>
            {request.id &&
                (<Segment.Group>
                    <Segment clearing>
                        <Header content='ส่วนงานไอที' color='teal' />
                        <Formik
                            enableReinitialize
                            initialValues={request}
                            onSubmit={(values, submitProps) => handleFormSubmit(values, submitProps)}
                        >
                            {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                                <Form className="ui form" onSubmit={handleSubmit} autoComplete='off'>
                                    {categoryOptions && categoryOptions.length > 0 && (
                                        <MySelectInput
                                            placeholder="ปัญหาเกิดจาก"
                                            name='categoryId'
                                            options={categoryOptions}
                                            disabled={!userStore.user?.roles?.includes('Admin') || request.statusId === 6}
                                            selectedChangeHandle={selectedChangeHandle}
                                        />)
                                    }
                                    <MyDateInput
                                        placeholderText='Date'
                                        name='appointmentDate'
                                        dateFormat='dd/MM/yyyy'
                                        disabled={!userStore.user?.roles?.includes('Admin') || request.statusId === 6}
                                    />
                                    <MyTextArea
                                        placeholder='การแก้ไขปัญหา'
                                        name='serviceDetail'
                                        rows={3}
                                        disabled={!userStore.user?.roles?.includes('Admin') || request.statusId === 6}
                                    />
                                    {userStore.user?.roles?.includes('Admin') &&
                                        <>
                                            <Button
                                                disabled={isSubmitting || !dirty || !isValid}
                                                floated='right'
                                                loading={isSubmitting}
                                                positive type='submit'
                                                content='บันทึก'
                                            />
                                            <Button
                                                as={Link}
                                                to={!request.id ? '/dashboard' : `/requests/${request.id}`}
                                                floated='right'
                                                type='button'
                                                content='ยกเลิก'
                                            />
                                        </>
                                    }
                                </Form>
                            )}
                        </Formik>
                    </Segment>
                    <Segment clearing attached='bottom'>
                        <RequestPhotoForm request={selectedRequest} roles='Admin' isUser={false} />
                    </Segment>
                </Segment.Group>)
            }
        </>
    )
})