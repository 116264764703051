import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { ratting } from "../../../app/models/request";
import { useStore } from "../../../app/stores/store";
import RequestDetailChat from "./RequestDetailChat";
import RequestDetailHeader from "./RequestDetailHeader";
import RequestDetailInfo from "./RequestDetailInfo";
import RequestDetailIt from "./RequestDetailIt";
import RequestDetailPhoto from "./RequestDetailPhoto";
import RequestDetailRating from "./RequestDetailRating";

export default observer(function RequestDetails() {
    const { requestStore } = useStore();
    const { selectedRequest: request, loadRequest, loadingInitial, clearSelectedRequest } = requestStore;
    const { id } = useParams<{ id: string }>();
    const ratting: ratting[] = [
        {
            type: 'quality',
            score: request?.quality
        },
        {
            type: 'quick',
            score: request?.quick
        },
        {
            type: 'serviceMind',
            score: request?.serviceMind
        }
    ]

    useEffect(() => {
        if (id) loadRequest(parseInt(id));
        return () => clearSelectedRequest();

    }, [id, loadRequest, clearSelectedRequest])

    if (loadingInitial || !request) return <LoadingComponent />;

    return (
        <Segment>
            <RequestDetailHeader request={request} />
            <RequestDetailInfo request={request} />
            <RequestDetailPhoto request={request} isUser={true} />
            <RequestDetailIt request={request} />
            <RequestDetailPhoto request={request} isUser={false} />
            <RequestDetailRating  ratting={ratting}/>
            <RequestDetailChat requestId={request.id.toString()} />
        </Segment>
    )
})