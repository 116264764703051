import React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useStore } from '../stores/store';

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  roles?: string[];
}

export default function PrivateRoute({ component: Component, roles, ...rest }: Props) {
  const { userStore: { user } } = useStore();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        }
        // check any roles that have been passed to the private routes and compare user roles ==> roles
        if (roles && !roles?.some(r => user.roles?.includes(r))) {
          toast.error('Not authorized to access this area');
          return <Redirect to={{ pathname: "/" }} />
        }
        return <Component {...props} />
      }}
    />
  )
}
