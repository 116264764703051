import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Header, Rating, Segment } from "semantic-ui-react";
import { ratting } from "../../../app/models/request";
import { useStore } from "../../../app/stores/store";

interface Props {
    ratting: ratting[];
}

export default observer(function RequestDetailRating({ ratting }: Props) {
    const { requestStore: { updateRatting, loading }, userStore: { user } } = useStore();
    const [score, setScore] = useState(ratting);

    function handleRatting(ratting: any, type: string) {
        let newScore = [...score];
        switch (type) {
            case 'quality':
                newScore[0].score = ratting;
                break;
            case 'quick':
                newScore[1].score = ratting;
                break;
            case 'service mind':
                newScore[2].score = ratting;
                break;
        }
        setScore(newScore);
    }

    function handleSetRatting() {
        updateRatting(score[0].score!, score[1].score!, score[2].score!);
    }

    return (
        <>
            <Segment
                textAlign="center"
                attached='top'
                inverted
                color="teal"
                style={{ border: 'none' }}
            >
                <Header>ความพึงพอใจ</Header>
            </Segment>
            <Segment attached clearing>
                <Rating icon='star' maxRating={5} size='huge' rating={score[1].score}
                    onRate={(e, { rating }) => handleRatting(rating, 'quick')} />
                <p>ความรวดเร็ว</p>
                <Rating icon='star' maxRating={5} size='huge' rating={score[0].score}
                    onRate={(e, { rating }) => handleRatting(rating, 'quality')} />
                <p>คุณภาพงานซ่อม</p>
                <Rating icon='heart' maxRating={5} size='huge' rating={score[2].score}
                    onRate={(e, { rating }) => handleRatting(rating, 'service mind')} />
                <p>ความใส่ใจในการบริการ</p>
                {!user?.roles?.includes('Admin') &&
                    <Button
                        primary
                        loading={loading}
                        content='ส่งคะแนน'
                        onClick={handleSetRatting}
                    />
                }
            </Segment>
        </>

    )
})