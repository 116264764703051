import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { Grid, Header, Icon, Popup, Segment } from "semantic-ui-react";
import { Request } from "../../../app/models/request";

interface Props {
  request: Request;
}

export default observer(function RequestDetailIt({ request }: Props) {
  return (
    <Segment.Group>
      <Segment
        textAlign="center"
        attached='top'
        inverted
        color="teal"
        style={{ border: 'none' }}
      >
        <Header>การแก้ไขปัญหา</Header>
      </Segment>
      <Segment attached>
        <Grid verticalAlign='middle'>
          <Grid.Column width={1}>
            <Popup
              hoverable
              trigger={
                <Icon name='calendar alternate outline' size="large" color="teal" />
              }
              content='กำหนดเสร็จ'
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <p>{!!request.appointmentDate && request.appointmentDate!.getFullYear() > 2001 &&
              format(request.appointmentDate!, 'dd MMM yyyy')}</p>
          </Grid.Column>
          <Grid.Column width={1}>
            <Popup
              hoverable
              trigger={
                <Icon name='ordered list' size="large" color="teal" />
              }
              content='ประเภท'
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <p>{request.categoryName}</p>
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment attached>
        <Grid verticalAlign='middle'>
          <Grid.Column width={1}>
            <Popup
              hoverable
              trigger={
                <Icon name="edit outline" size="large" color="teal" />
              }
              content='การแก้ไข'
            />
          </Grid.Column>
          <Grid.Column width={15}>
            <p>{request.serviceDetail}</p>
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment.Group>
  )
})