import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import Login from "../../users/Login";
import Register from "../../users/Register";

export default observer(function HomePage() {
  const { userStore, modalStore } = useStore();
  return (
    <Segment inverted textAlign='center' vertical className='masthead'>
      <Container text>
        <Header as='h1' inverted>
          IT Services
        </Header>
        {userStore.isLoggedIn ? (
          <>
            <Header as='h2' inverted content='Welcome to IT Services' />
            <Button as={Link} to='/dashboard' size='huge' inverted >
              Go to IT services!
            </Button>
            {userStore.user?.roles?.includes('Admin') &&
              <Button onClick={() => modalStore.openModal(<Register />)} size='huge' inverted >
                Register!
              </Button>
            }
          </>
        ) : (
          <>
            <Button onClick={() => modalStore.openModal(<Login />)} size='huge' inverted >
              Login!
            </Button>
            
          </>
        )}
      </Container>
    </Segment>
  )
})