import { observer } from "mobx-react-lite";
import { Grid, Icon, Popup, Segment } from "semantic-ui-react";
import { Request } from "../../../app/models/request";

interface Props {
    request: Request;
}

export default observer(function RequestDetailInfo({ request }: Props) {
    return (
        <Segment.Group>
            <Segment attached='top'>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Popup
                            hoverable
                            trigger={
                                <Icon name='code' size="large" color="teal" />
                            }
                            content='รหัสทรัพย์สิน'
                        />
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <p>{request.assetCode}</p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Popup
                            hoverable
                            trigger={
                                <Icon name="info" size="large" color="teal" />
                            }
                            content='ปัญหาที่พบ'
                        />
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <p>{request.description}</p>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Segment.Group>
    )
})