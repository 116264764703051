import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container } from "semantic-ui-react";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import HomePage from "../../features/activities/home/HomePage";
import RequestDashboard from "../../features/activities/dashboard/RequestDashboard";
import Login from "../../features/users/Login";
import Register from "../../features/users/Register";
import ModalContainer from "../common/modals/ModalContainer";
import { useStore } from "../stores/store";
import NavBar from "./NavBar";
import LoadingComponent from "./LoadingComponent";
import RequestForm from "../../features/activities/form/RequestForm";
import RequestDetails from "../../features/activities/details/RequestDetails";
import PrivateRoute from "./PrivateRoute";
import RequestReport from "../../features/activities/reports/RequestReport";

function App() {
  const location = useLocation();
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore])

  if (!commonStore.appLoaded) return <LoadingComponent content='Loading app...' />

  return (
    <>
      <ModalContainer />
      <ToastContainer position='bottom-right' hideProgressBar theme='colored' />
      <Route exact path='/' component={HomePage} />
      <Route
        path={'/(.+)'}
        render={() => (
          <>
            <NavBar />
            <Container style={{ marginTop: '7em' }}>
              <Switch>
                <PrivateRoute exact path='/dashboard' component={RequestDashboard} />
                <PrivateRoute path='/requests/:id' component={RequestDetails} />
                <PrivateRoute key={location.key} path={['/createRequest', '/manage/:id']} component={RequestForm} />
                <PrivateRoute roles={['Admin']} path='/register' component={Register} />
                <PrivateRoute roles={['Admin']} path='/reports' component={RequestReport} />
                <PrivateRoute path='/report' component={RequestReport} />
                <Route path='/login' component={Login} />
                <Route path='/server-error' component={ServerError} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </>
        )} />
    </>
  );
}
export default observer(App);
