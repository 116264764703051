import { Link } from 'react-router-dom'
import { Button, Header, Icon, Segment } from 'semantic-ui-react'

export default function NotFound() {
  return (
      <Segment>
          <Header icon>
              <Icon name='search' />
              ไม่พบข้อมูล
          </Header>
          <Segment.Inline>
              <Button as={Link} to='/dashboard' primary>
                  กลับสู่ Dashborad
              </Button>
          </Segment.Inline>
    </Segment>
  )
}