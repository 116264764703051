import { useEffect, useState } from "react";
import { Button, Grid, Header } from "semantic-ui-react";
import PhotoWidgetCropper from "./PhotoWidgetCropper";
import PhotoWidgetDropzone from "./PhotoWidgetDropzone";

interface Props {
    loading: boolean;
    uploadPhoto: (file: Blob,fileName: string) => void;
}

export default function PhotoUploadWidget({ loading, uploadPhoto }: Props) {
    const [files, setFiles] = useState<any>([]);
    const [cropper, setCropper] = useState<Cropper>();

    function onCrop() {
        if (cropper) {
            cropper.getCroppedCanvas({
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
            });
            //console.log(files)
            cropper.getCroppedCanvas().toBlob(blob => uploadPhoto(blob!,files[0].name),'image/jpeg', 0.75);
        }
    }

    useEffect(() => {
        return () => {
            files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        }
    }, [files])


    return (
        <Grid>
            <Grid.Column width={4}>
                <Header color="teal" as='h4' content='ขั้นตอน 1 - เพิ่มรูป' />
                <PhotoWidgetDropzone setFiles={setFiles} />
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4} >
                <Header color="teal" as='h4' content='ขั้นตอน 2 - ลดขนาดรูป' />
                {files && files.length > 0 && (
                    <PhotoWidgetCropper
                        setCropper={setCropper}
                        imagePreview={files[0].preview}
                    />
                )}
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4} >
                <Header color="teal" as='h4' content='ขั้นตอน 3 - พรีวิว & อัพโหลด' />
                {
                    files && files.length > 0 &&
                    <>
                        <div className='img-preview' style={{ minHeight: 200, overflow: 'hidden' }} />
                        <Button.Group>
                            <Button loading={loading} onClick={onCrop} positive icon='check' style={{width: 100}} />
                            <Button disabled={loading} onClick={() => setFiles([])} icon='close' style={{width: 100}} />
                        </Button.Group>
                    </>

                }
            </Grid.Column>
        </Grid>
    )
}