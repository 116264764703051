import { observer } from "mobx-react-lite"
import { SyntheticEvent, useState } from "react";
import { Button, Card, Grid, Header, Image, Segment } from "semantic-ui-react";
import PhotoUploadWidget from "../../../app/common/imageUpload/PhotoUploadWidget";
import { Photo, Request } from "../../../app/models/request";
import { useStore } from "../../../app/stores/store";

interface Props {
  request: Request | undefined;
  roles: string;
  isUser: boolean;
}

export default observer(function RequestPhotoForm({ request, roles, isUser }: Props) {
  const { requestStore: { loading, uploading, uploadPhoto, deletePhoto },
    userStore: { user }, modalStore } = useStore();
  const [addPhotoMode, setAddPhotoMode] = useState(false);
  const [target, setTarget] = useState('');

  function handlePhotoUpload(file: Blob, fileName: string) {
    if (!!request?.id) {
      uploadPhoto(file, request.id, fileName).then(() => setAddPhotoMode(false));
    }
  }

  function handleDeletePhoto(photo: Photo, e: SyntheticEvent<HTMLButtonElement>) {
    setTarget(e.currentTarget.name);
    deletePhoto(photo, request?.id!);
  }

  function handleShowPhoto(photo: Photo, e: SyntheticEvent<HTMLButtonElement>) {
    setTarget(e.currentTarget.name);
    modalStore.openModal(<Image src={photo.url} />,'small')
  }
  return (
    <Segment>
      <Grid>
        <Grid.Column width={16}>
          <Header floated="left" icon='image' content='รูปภาพ' color='teal' />
          <Button
            floated="right"
            basic
            content={addPhotoMode ? 'ยกเลิก' : 'เพิ่ม รูป'}
            onClick={() => setAddPhotoMode(!addPhotoMode)}
            disabled={roles === 'User' ? !!request?.id ? user?.roles?.includes('Admin') : true
              : !!request?.id ? !user?.roles?.includes('Admin') : true}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          {addPhotoMode ? (
            <PhotoUploadWidget
              uploadPhoto={handlePhotoUpload}
              loading={uploading}
            />
          ) : (
            <Card.Group itemsPerRow={5}>
              {request?.photos.map(photo => (
                photo.isUser === isUser &&
                <Card key={photo.id}>
                  <Image src={photo.url} />
                  <Button.Group fluid widths={2}>
                    <Button
                      basic
                      color='green'
                      content='แสดง'
                      name={'show' + photo.id}
                      loading={target === 'show' + photo.id && loading}
                      onClick={e => handleShowPhoto(photo, e)}
                    />
                    <Button
                      basic
                      color='red'
                      icon='trash'
                      name={photo.id}
                      loading={target === photo.id && loading}
                      onClick={e => handleDeletePhoto(photo, e)}
                      disabled={roles === 'User' ? !!request?.id ? user?.roles?.includes('Admin') : true
                        : !!request?.id ? !user?.roles?.includes('Admin') : true}
                    />
                  </Button.Group>
                </Card>
              ))}
            </Card.Group>
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  )
})