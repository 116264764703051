import { observer } from "mobx-react-lite";
import { Modal } from "semantic-ui-react";
import { useStore } from "../../stores/store";

export default observer(function ModalContainer() {
    const { modalStore } = useStore();
    return (
      <Modal open={modalStore.modal.open}  size={modalStore.modal.size === 'mini' ? 'mini' : 'small'} onClose={modalStore.closeModal}>
            <Modal.Content>
                {modalStore.modal.body}
            </Modal.Content>
      </Modal>
    )
  })