import { useField } from "formik";
import { Checkbox, Form, Label } from "semantic-ui-react";

interface Props {
    name: string;
    label?: string;
    disabled?: boolean;
    checked?: boolean;
    checkedChange: (value: boolean) => void;
}

export default function MyCheckBox(props: Props) {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(props.name); 
    
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <Checkbox
                label={props.label}
                // value={field.checked ? '1' : '0'}
                onChange={(e, v) => {
                    helpers.setValue(v.value);
                    props.checkedChange(v.checked as boolean);
                }}
                disabled={props.disabled}
                checked={props.checked}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}