import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { history } from "../..";
import agent from "../api/agent";
import { User, UserFormValues } from "../models/user";
import { store } from "./store";

export default class UserStore {
    // if login user = user if not login = null
    user: User | null = null;
    refreshTokenTimeout: any;

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        // !this.user ==> this.user === null
        return !!this.user;
    }

    login = async (creds: UserFormValues) => {
        try {
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
            runInAction(() => {
                // Add roles
                let claims = JSON.parse(atob(user.token.split('.')[1]));
                let roles = claims['role'];
                user.roles = typeof(roles) === 'string' ? [roles] : roles;
                this.user = user
            });
            history.push('/dashboard');
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        store.commonStore.setToken(null);
        //store.requestStore.clearPagination();
        //store.requestStore.clearRequestRegistry();
        //store.requestStore.clearSelectedRequest();
        window.localStorage.removeItem('jwt');
        this.user = null;
        history.push('/');
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            store.commonStore.setToken(user.token);
            runInAction(() => {
                let claims = JSON.parse(atob(user.token.split('.')[1]));
                let roles = claims['role'];
                user.roles = typeof(roles) === 'string' ? [roles] : roles;
                this.user = user
            });
            this.startRefreshTokenTimer(user);
        } catch (error) {
            console.log(error);
        }
    }

    register = async (creds: UserFormValues) => {
        try {
            await agent.Account.register(creds);
            toast.success('ลงทะเบียนเสร็จแล้ว');
            history.push('/');
            store.modalStore.closeModal();
            //history.push(`/account/registerSuccess?email=${creds.email}`);
        } catch (error) {
            throw error;
        }
    }

    // setImage = (image: string) => {
    //     if (this.user) this.user.image = image;
    // }

    setDisplayName = (name: string) => {
        if (this.user) this.user.displayName = name;
    }

    refreshToken = async () => {
        this.stopRefreshTokenTimer();
        try {
            const user = await agent.Account.refreshToken();
            runInAction(() => {
                let claims = JSON.parse(atob(user.token.split('.')[1]));
                let roles = claims['role'];
                user.roles = typeof(roles) === 'string' ? [roles] : roles;
                this.user = user
            });
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
        } catch (error) {
            console.log(error);
        }
    }

    private startRefreshTokenTimer(user: User) {
        const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        // refresh ก่อนหมดเวลา 60 วินาที
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

}