import { Fragment } from "react";
import { Button, Placeholder, Segment } from "semantic-ui-react";

export default function RequestListItemPlaceholder() {
  return (
    <Fragment>
            <Placeholder fluid style={{ marginTop: 25 }}>
                <Segment.Group>
                    <Segment style={{ minHeight: 80 }}>
                        <Placeholder>
                            <Placeholder.Header image>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                        </Placeholder>
                    </Segment>
                    <Segment secondary style={{ minHeight: 60 }} />
                    <Segment clearing>
                        <Button disabled color='teal' floated='right' content='รายละเอียด' />
                    </Segment>
                </Segment.Group>
            </Placeholder>
        </Fragment>
  )
}
