export interface Pagination{
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
    numOpenJob: number;
    numWipJob: number;
    numFinishJob: number;
    numNotRepairJob: number;
    numCancelJob: number;
    numCloseJob: number;
}

export class PaginatedResult<T>{
    data: T;
    pagination: Pagination;

    constructor(data: T, pagination: Pagination) {
        this.data = data;
        this.pagination = pagination;
    }
}

export class PagingParams{
    pageNumber;
    pageSize;
    
    constructor(pageNumber = 1, pageSize = 4) {
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
    }
}