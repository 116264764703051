import { observer } from "mobx-react-lite";
import { Link, NavLink } from "react-router-dom";
import { Button, Container, Dropdown, Menu, Image } from "semantic-ui-react";
import { useStore } from "../stores/store";

export default observer(function NavBar() {
    const { userStore: { user, logout, isLoggedIn } } = useStore();

    function signOut() {
        logout();
    }
    return (
        <Menu inverted fixed='top'>
            <Container>
                <Menu.Item as={NavLink} to='/' exact header>
                    Home
                </Menu.Item>
                {isLoggedIn
                    &&
                    <>
                        <Menu.Item as={NavLink} to='/dashboard' name='Dashboard' />
                        {!user?.roles!.includes('Admin') &&
                            <Menu.Item>
                                <Button as={NavLink} to='/createRequest' positive content='สร้างใบแจ้งซ่อม' />
                            </Menu.Item>
                        }
                        <Menu.Item>
                            <Dropdown pointing='top left' text="Report">
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to={`/reports`} text='สรุปการทำงาน' icon='th list' />
                                    <Dropdown.Item as={Link} to={`/reports`} text='สรุปการทำงานประจำปี' icon='th list' />
                                    <Dropdown.Item as={Link} to={`/reports`} text='สรุปคะแนนความพึงพอใจ' icon='th list' />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>

                        <Menu.Item position='right'>
                            <Image src={'/assets/user.png'} avatar spaced='right' />
                            <Dropdown pointing='top left' text={user?.displayName}>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        as={Link}
                                        to={`/profiles/${user?.username}`}
                                        text='My Profile'
                                        icon='user' />
                                    <Dropdown.Item onClick={signOut} text='Logout' icon='power' />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </>}
            </Container>
        </Menu>
    )
})