import { format } from "date-fns";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Pagination, PagingParams } from "../models/pagination";
import { Filter, Photo, Request, RequestFormValues } from "../models/request";

export default class RequestStore {
    requestRegistry = new Map<string, Request>();
    selectedRequest: Request | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    loadingFilter = false;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    predicate = new Map().set('status', 'ทั้งหมด');
    filter: Filter | null = null;
    uploading = false;
    branchFilter: string[] = [];
    searchTerm: string = '';


    constructor() {
        makeAutoObservable(this);
        // "loadRequests()" is called on every time a key in the predicate changes
        reaction(
            () => this.predicate.keys(),
            () => {
                this.pagingParams = new PagingParams();
                this.requestRegistry.clear();
                this.loadRequests();
            }
        )
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPredicate = (predicate: string, value: string | Date | string[]) => {
        const resetPredicate = () => {
            this.predicate.forEach((value, key) => {
                if (key !== 'startDate' && key !== 'searchTerm' && key !== 'branch') this.predicate.delete(key);
            })
        }

        switch (predicate) {
            case 'startDate':
                resetPredicate();
                this.predicate.delete('startDate');
                this.predicate.set(predicate, value);
                break;
            case 'searchTerm':
                this.predicate.delete('searchTerm');
                this.searchTerm = value.toString();
                this.predicate.set(predicate, value);
                break;
            case 'branch':
                this.branchFilter = value as string[];
                resetPredicate();
                this.predicate.delete('branch');
                this.predicate.set(predicate, value);
                break;
            default:
                resetPredicate();
                this.predicate.set(predicate, value);
                break;
        }
    }

    // set header parameter
    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                // important for send date
                params.append(key, (value as Date).toLocaleString('en-En'));
            } else {
                params.append(key, value);
            }
        })
        //console.log(params.toString());
        return params;
    }

    get requestsByDate() {
        return Array.from(this.requestRegistry.values()).sort((a, b) =>
            a.date!.getTime() - b.date!.getTime());
    }

    get groupedRequests() {
        return Object.entries(
            this.requestsByDate.reduce((requests, request) => {
                const date = format(request.date!, 'dd MMM yyyy');
                requests[date] = requests[date] ? [...requests[date], request] : [request];
                return requests;
            }, {} as { [key: string]: Request[] })
        )
    }

    loadFilters = async () => {
        try {
            const result = await agent.Requests.fetchFilters();
            this.setFilters(result);
            runInAction(() => {
                this.loadingFilter = true;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingFilter = false;
            })
        }
    }

    loadRequests = async () => {
        this.loadingInitial = true;
        try {
            const result = await agent.Requests.list(this.axiosParams);
            //console.log(result);
            runInAction(() => {
                this.requestRegistry.clear();
            })
            result.data.forEach(request => {
                this.setRequest(request);
            })
            //console.log(result.pagination);
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    private setFilters = (filter: Filter) => {
        this.filter = filter;
    }

    private setRequest = (request: Request) => {
        request.date = new Date(request.date!);
        if (request.appointmentDate !== null) request.appointmentDate = new Date(request.appointmentDate!);
        // console.log(request);
        this.requestRegistry.set(request.id.toString(), request);
    }

    private getRequest = (id: number) => {
        return this.requestRegistry.get(id.toString());
    }

    loadRequest = async (id: number) => {
        let request = this.getRequest(id);
        if (request) {
            this.selectedRequest = request;
            return request;
        } else {
            this.loadingInitial = true;
            try {
                request = await agent.Requests.details(id);
                this.setRequest(request);
                runInAction(() => {
                    this.selectedRequest = request;
                });
                this.setLoadingInitial(false);
                return request;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    createRequest = async (request: RequestFormValues) => {
        try {
            const response = await agent.Requests.create(request);
            const newRequest = new Request(response);
            this.setRequest(newRequest);
            runInAction(() => {
                this.selectedRequest = newRequest;
                // if(typeof(this.pagination?.numOpenJob) === 'number'){
                //     this.pagination.numOpenJob += 1;
                // }
            })
            toast.success('ข้อมูลถูกบันทึกแล้ว');
            return newRequest;
        } catch (error) {
            console.log(error);
        }
    }

    updateRequest = async (request: RequestFormValues) => {
        try {
            await agent.Requests.update(request)
            runInAction(() => {
                if (request.id) {
                    let updateRequest = { ...this.getRequest(request.id), ...request };
                    // we know this updated activity contains all of these properties and additional properties
                    this.requestRegistry.set(request.id.toString(), updateRequest as Request);
                    this.selectedRequest = updateRequest as Request;
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    deleteRequest = async (id: number) => {
        this.loading = true;
        try {
            await agent.Requests.delete(id);
            runInAction(() => {
                this.requestRegistry.delete(id.toString());
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    cancelRequestToggle = async () => {
        this.loading = true;
        try {
            await agent.Requests.attend(this.selectedRequest!.id);
            runInAction(() => {
                this.selectedRequest!.isCancelled = !this.selectedRequest!.isCancelled;
                this.requestRegistry.set(this.selectedRequest!.id.toString(), this.selectedRequest!);
            })
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => this.loading = false);
        }
    }

    updateRequestStatus = async (statusId: number) => {
        this.loading = true;
        try {
            if (this.selectedRequest!.statusId === statusId) return;
            await agent.Requests.updateStatus(this.selectedRequest!.id, statusId);
            runInAction(() => {
                this.selectedRequest!.statusId = statusId;
                this.selectedRequest!.status = this.filter!.status[statusId - 1].name;
                this.requestRegistry.set(this.selectedRequest!.id.toString(), this.selectedRequest!);
            })

        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => this.loading = false);
        }
    }

    updateRatting = async (quality: number, quick: number, serviceMind: number) => {
        this.loading = true;
        try {
            if (this.selectedRequest!.quality === quality &&
                this.selectedRequest!.quick === quick &&
                this.selectedRequest!.serviceMind === serviceMind) {
                return;
            }
            await agent.Requests.ratting(this.selectedRequest!.id, quality, quick, serviceMind);
            runInAction(() => {
                this.selectedRequest!.quality = quality;
                this.selectedRequest!.quick = quick;
                this.selectedRequest!.serviceMind = serviceMind;
                this.requestRegistry.set(this.selectedRequest!.id.toString(), this.selectedRequest!);
            })
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => this.loading = false);
        }
    }

    clearSelectedRequest = () => {
        this.selectedRequest = undefined;
    }

    clearRequestRegistry = () => {
        this.requestRegistry.clear();
    }

    clearPagination = () => {
        this.pagingParams = new PagingParams();
    }

    uploadPhoto = async (file: Blob, id: number, fileName: string) => {
        this.uploading = true;
        try {
            const response = await agent.Requests.uploadPhoto(file, id, fileName);
            const photo = response.data;
            runInAction(() => {
                if (this.selectedRequest) {
                    this.selectedRequest.photos.push(photo);
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => this.uploading = false);
        }
    }

    deletePhoto = async (photo: Photo, requestId: number) => {
        this.loading = true;
        try {
            await agent.Requests.deletePhoto(photo.id, requestId);
            runInAction(() => {
                if (this.selectedRequest) {
                    this.selectedRequest.photos = this.selectedRequest.photos.filter(p => p.id !== photo.id);
                }
            })
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => this.loading = false);
        }
    }

}