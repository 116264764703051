import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, Grid, GridColumn, Icon, Item, Label, Popup, Rating, Segment } from "semantic-ui-react";
import { Request } from "../../../app/models/request";

interface Props {
    request: Request;
}

export default observer(function RequestListItem({ request }: Props) {
    return (
        <Popup
            key={request.id}
            position='right center'
            trigger={
                <Segment.Group>
                    <Segment>
                        {request.isCancelled &&
                            <Label attached="top" color="red" content="ยกเลิก" style={{ texAlign: 'center' }} />
                        }
                        <Item.Group>
                            <Item>
                                <Item.Image style={{ marginBottom: 5 }} size='tiny' circular src={'/assets/user.png'} />
                                <Item.Content>
                                    <Item.Header as={Link} to={`/requests/${request.id}`}>
                                        {request.title}
                                    </Item.Header>
                                    <Item.Description>
                                        แจ้งโดย <Link to={`/profiles/${request.hostUsername}`}>{request.attendees[0].displayName}</Link>
                                    </Item.Description>
                                    <Item.Description>
                                        <Label basic color='green'>
                                            รหัสทรัพย์สิน {request.assetCode}
                                        </Label>
                                    </Item.Description>
                                </Item.Content>
                            </Item>
                        </Item.Group>
                    </Segment>

                    <Segment>
                        <Grid columns='equal'>
                            <Grid.Column width={4}>
                                <Popup
                                    hoverable
                                    trigger={
                                        <Icon name='clock' />
                                    }
                                    content='วันที่สร้าง'
                                />
                                {format(request.date!, 'dd MMM yyyy')}
                            </Grid.Column>
                            <GridColumn width={5}>
                                <Popup
                                    hoverable
                                    trigger={
                                        <Icon name="tag" />
                                    }
                                    content='สถานะ'
                                />
                                {request.status}
                            </GridColumn>
                            <GridColumn width={5}>
                                <Popup
                                    hoverable
                                    trigger={
                                        <Icon name='calendar alternate outline' />
                                    }
                                    content='กำหนดเสร็จ'
                                />
                                {!!request.appointmentDate && request.appointmentDate!.getFullYear() > 2001 &&
                                    format(request.appointmentDate!, 'dd MMM yyyy')}
                            </GridColumn>
                            <Grid.Column floated='right' width={2}>
                                <Popup
                                    hoverable
                                    trigger={
                                        <Icon name='code branch' />
                                    }
                                    content='สาขา'
                                />
                                {request.branchName}
                            </Grid.Column>
                        </Grid>
                    </Segment>

                    <Segment clearing>
                        <span>{request.description}</span>
                        <Button
                            as={Link}
                            to={`/requests/${request.id}`}
                            color='teal'
                            floated='right'
                            content='รายละเอียด'
                        />
                    </Segment>

                </Segment.Group>
            }
        >
            <Popup.Header>ความพึงพอใจ</Popup.Header>
            <Popup.Content>
                <Rating icon='star' rating={Math.round((request.quality + request.quick + request.serviceMind) / 3)} maxRating={5} />
            </Popup.Content>
        </Popup>

    )
})