import { Field, FieldProps, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Comment, Header, Loader, Segment } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

interface Props {
    requestId: string;
}

export default observer(function RequestDetailChat({ requestId }: Props) {
    const { commentStore } = useStore();

    useEffect(() => {
        if (requestId) {
            commentStore.createHubConnection(requestId);
        }
        return () => {
            commentStore.clearComments();
        }
    }, [commentStore, requestId])
    return (
        <>
            <Segment
                textAlign="center"
                attached='top'
                inverted
                color="teal"
                style={{ border: 'none' }}
            >
                <Header>แสดงความคิดเห็น</Header>
            </Segment>
            <Segment attached clearing>
                <Formik
                    onSubmit={(values, { resetForm }) => commentStore.addComment(values).then(() => resetForm())}
                    initialValues={{ body: '' }}
                    validationSchema={Yup.object({ body: Yup.string().required() })}
                >
                    {({ isSubmitting, isValid, handleSubmit }) => (
                        <Form className="ui form">
                            <Field name='body'>
                                {(props: FieldProps) => (
                                    <div style={{ position: 'relative' }}>
                                        <Loader active={isSubmitting} />
                                        <textarea
                                            placeholder='พิมพ์ข้อความที่นี่... (กดปุ่ม Enter เพื่อส่งข้อความ , กดปุ่ม SHIFT + Enter เพื่อขึ้นบรรทัดใหม่)'
                                            rows={2}
                                            {...props.field}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter' && e.shiftKey) {
                                                    return;
                                                }
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    isValid && handleSubmit();
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </Field>
                        </Form>
                    )}
                </Formik>

                <Comment.Group>
                    {commentStore.comments.map(comment => (
                        <Comment key={comment.id}>
                            <Comment.Avatar src={'/assets/user.png'} />
                            <Comment.Content>
                                <Comment.Author as={Link} to={`/profile/${comment.username}`}>
                                    {comment.displayName}
                                </Comment.Author>
                                <Comment.Metadata>
                                    <div>{formatDistanceToNow(comment.createdAt)} ago</div>
                                </Comment.Metadata>
                                <Comment.Text style={{whiteSpace: 'pre-wrap'}}>{comment.body}</Comment.Text>
                            </Comment.Content>
                        </Comment>
                    ))}
                </Comment.Group>

            </Segment>
        </>
    )
})