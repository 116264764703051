import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { history } from '../..';
import { PaginatedResult } from "../models/pagination";
import { SumRequest } from "../models/report";
import { Filter, Photo, Request, RequestFormValues } from "../models/request";
import { User, UserFormValues } from "../models/user";
import { store } from "../stores/store";

//Create Sleep
const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// config request
axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token) config.headers!.Authorization = `Bearer ${token}`
    return config;
})

// config response
axios.interceptors.response.use(async response => {
    if (process.env.NODE_ENV === 'development') await sleep(1000);

    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }
    return response;
}, (error: AxiosError) => {
    //console.log(error.response);
    const { data, status, config, headers } = error.response!;

    switch (status) {
        case 400:
            if (typeof data === 'string') {
                toast.error(data);
            }
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                history.push('/not-found');
            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key]);
                    }
                }
                throw modalStateErrors.flat();
            }
            break;
        case 401:
            if (status === 401 && headers['www-authenticate']?.startsWith('Bearer error="invalid_token"')) {
                store.userStore.logout();
                toast.error('Session expired - please login again');
            } else {
                toast.error(data);
            }
            break;
        case 403:
            toast.error('คุณไม่ได้รับสิทธิในการใช้งาน');
            break;
        case 404:
            history.push('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
        default:
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user),
    refreshToken: () => requests.post<User>('/account/refreshToken', {}),
}

const Requests = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Request[]>>('/request', { params })
        .then(responseBody),
    details: (id: number) => requests.get<Request>(`/request/${id}`),
    create: (request: RequestFormValues) => requests.post<Request>('/request', request),
    update: (request: RequestFormValues) => requests.put<void>(`/request/${request.id}`, request),
    delete: (id: number) => requests.del<void>(`/request/${id}`),
    attend: (id: number) => requests.post<void>(`/request/${id}/attend`, {}),
    updateStatus: (id: number, statusId: number) => requests.post<void>(`/request/${id}/update/${statusId}`, {}),
    ratting: (id: number, quality: number, quick: number, serviceMind: number) =>
        requests.post<void>(`/request/${id}/ratting/${quality}/${quick}/${serviceMind}`, {}),
    fetchFilters: () => requests.get<Filter>('request/filters'),
    uploadPhoto: (file: Blob, id: number, fileName: string) => {
        let formData = new FormData();
        formData.append('requestId', id.toString());
        formData.append('File', file);
        formData.append('FileName', fileName);
        return axios.post<Photo>('/photos', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });

    },
    deletePhoto: (id: string,requestId: number) => requests.del(`/photos/${id}/${requestId}`),
}

const Reports = {
    sumReport: (startDate: string, endDate: string) => requests.post<SumRequest[]>(`/report/${startDate}/${endDate}`, {}),
}

const agent = {
    Account,
    Requests,
    Reports 
}

export default agent;